import { useEffect, useState } from "react";
import PostService from "../API/PostService";
import PaymentForm from "./PaymentForm";

function LessonList( { lessons, isTeacher, onPay } ) {
    console.log(lessons);
    return (
        <div className="lessons-list">
            <div className="lessons-list-header">
                Предыдущие уроки
            </div>
            { lessons !== undefined && lessons.length !== 0 ?
                <div>
                    {lessons.map((lesson, index) => 
                        <LessonCard lessonInit={lesson} isTeacher={isTeacher} onPay={onPay} />
                    )}
                </div>
                : <></>
            }

        </div>
    )
}

export default LessonList

function LessonCard( { lessonInit, isTeacher, onPay } ) {

    const [token, setToken] = useState('')

    const makePayment = async () => {
        const response = await PostService.makePaymentByLessonId(lessonInit.id)
        if ( response.status !== 200)
        {
            console.log(response);
        }
        else
        {
        }
        console.log(response.data.confirmation.confirmation_token);
        setToken(response.data.confirmation.confirmation_token)
        localStorage.setItem('pickedLesson', JSON.stringify(lessonInit))
        onPay(lessonInit)
    }

    console.log('lessonCard', lessonInit);
    const created_at = new Date(lessonInit.created_at);
    const date_of_lesson = new Date(lessonInit.date_of_lesson);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
        };
    const formatter = new Intl.DateTimeFormat('ru-RU', options);
    const formattedCreatedDate = formatter.format(created_at);
    const formattedLessonDate = formatter.format(date_of_lesson);

    return(
        lessonInit !== undefined ?
        <div className='lesson-card'>
            <div className='lesson-date'>Создан: {formattedCreatedDate}</div>
            <div className='lesson-date'>Последнее обновление: {formattedCreatedDate}</div>
            <div className='lesson-price'>Комиссия {lessonInit.debt} руб.</div>
            {lessonInit.is_paid || lessonInit.debt === 0 ? <div className="button-info">Оплачено</div> : <div className="button-pay" onClick={makePayment}>Оплатить</div>}
            {
                token.length === 0 ?
                <></>
                :
                <PaymentForm token={token} />
            }
        </div>
        : <></>
    )
 }

