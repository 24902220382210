import { useEffect, useState, useContext } from 'react'
import '../App.css'
import { AuthContext } from '../hoc/AuthProvider'
import PostService from '../API/PostService'
import TeachersList from '../components/TeachersList';
import { useNavigate } from 'react-router-dom';
import ModalRequest from '../components/ModalRequest';
const tgWebApp = window.Telegram.WebApp;

function TeacherSearchPage() {

    const navigate = useNavigate()

    const goBack = () => navigate('/filter', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)
    
    const [teacherList, setTeacherList] = useState([])
    const [isFound, setIsFound] = useState(false)
    const [showButtons, setShowButtons] = useState(true)
    const { GFilter } = useContext(AuthContext)
    const [requestModal, setRequestModal] = useState(false)

    const handleCreateRequest = async () => {
        let response = await PostService.refreshToken()
        console.log(response);
        if ( !(Object.hasOwn(GFilter, 'lesson_targets')) || !(Object.hasOwn(GFilter, 'teacher_statuses'))
            || (Object.hasOwn(GFilter, 'ages')) || GFilter.price === 0 ||  GFilter.description.length === 0 )
        {
            console.log(GFilter);
        }
        setRequestModal(true)
        
    }

    const processScience = ( teacher, science_assoc ) => {
        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === science_assoc.science_id )

        if ( scienceObj === undefined )
            return

        let scienceAges = ''
        science_assoc.age_ids.forEach( (ageId) =>
        {
            const age = JSON.parse(localStorage.getItem('GAges')).find( age => age.id === ageId)
            scienceAges += age.name + '; '
        })

        let scienceTargets = ''
        science_assoc.lesson_target_ids.forEach( (targetId) =>
        {
            const target = JSON.parse(localStorage.getItem('GTargets')).find( trg => trg.id === targetId)
            scienceTargets += target.name + '; '
        })

        let scienceStatus = JSON.parse(localStorage.getItem('GStatuses')).find( sts => sts.id === science_assoc.teacher_status).name

        return {
            'teacher': teacher,
            'science': scienceObj,
            'ages': scienceAges,
            'targets': scienceTargets,
            'status': scienceStatus
        }
    }

    useEffect( () => {
        const getTeachers = async () => {
            console.log(GFilter);
            let response = await PostService.getTeacherList(GFilter, GFilter.experience, "rating", "asc", GFilter.price)
            if ( response.status !== 200 )
            {
                console.log(response);
            }
            if ( !response.data.length )
            {
                response = await PostService.getTeacherList({}, 0, "rating", "asc", 0) 
                if ( response.status !== 200 )
                {
                    console.log(response);
                }
            }
            else
            {
                setIsFound(true)
            }
            let processedTeachers = []
            response.data.forEach( (teacher) =>  {
                const science_association = teacher.science_associations[0]
                processedTeachers.push(processScience(teacher, science_association))
            })
            setTeacherList(processedTeachers)
        }

        getTeachers()
    },[])

    return (
        <div className='teacher-search-page'>
            <div className='teacher-search-title'>{isFound ? `Подходящие репетиторы` : `По вашему запросу репетиторы не найдены, но вот те, которые могут вам подойти`}</div>
            {
                !isFound &&  showButtons?
                <div className='request-container'>
                    <div className='request-description'>Вы можете оставить заявку, и мы поищем репетитора под ваш запрос в нашей дополнительной базе. Хотите отправить заявку?</div>
                    <div className='button-container'>
                            <div className='button' onClick={()=>setShowButtons(false)}>Нет</div>
                            <div className='button' onClick={handleCreateRequest}>Да</div>
                        </div>
                </div>
                : <></>
            }
            {
                teacherList !== undefined ?
                    <TeachersList teachersInit={teacherList}></TeachersList>
                : <></>
            }
            <ModalRequest active={requestModal} setActive={setRequestModal} />
        </div>
    )
}

export default TeacherSearchPage