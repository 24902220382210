import OrderCard from "./OrderCard"

function OrderList( { orders, onResponse, price } ) {

    return (
        <div className='orders-list'>
            <div className='orders-list-header'>
                Заказы
            </div>
            { orders !== undefined && orders.length !== 0 ?
                <div>
                    {orders.map((order, index) => 
                        <OrderCard orderInit={order} onResponse={onResponse} priceInit={price} />
                    )}
                </div>
                : <></>
            }

        </div>
    )
}

export default OrderList