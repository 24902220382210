import { useContext, useEffect, useState } from 'react'
import '../App.css'
import { AuthContext } from '../hoc/AuthProvider'
import PostService from '../API/PostService'
import TextareaAutosize from 'react-textarea-autosize'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'

const ModalRequest = ( { active, setActive } ) => {

    const { GFilter } = useContext(AuthContext)

    const navigate = useNavigate()

    const goRequests = () => navigate('/student/requests', {replace: true})

    const [sciences, setSciences] = useState([])
    const [scTargets, setScTargets] = useState([])
    const [scAges, setScAges] = useState([])
    const [scStatuses, setScStatuses] = useState([])
    const [targetValues, setTargetValues] = useState([])
    const [ageValue, setAgeValue] = useState([])
    const [statusValues, setStatusValues] = useState([])

    const [strTargets, setStrTargets] = useState("")
    const [strAges, setStrAges] = useState("")
    const [strStatuses, setStrStatuses] = useState("")

    const [price, setPrice] = useState(0)
    const [description, setDescription] = useState("")

    const [addInfo, setAddInfo] = useState(false)

    const handleAgesChange = ( selectedOption ) => {
        setAgeValue( selectedOption )
    }

    const handleStatusesChange = ( selectedOptions ) => {
        setStatusValues(selectedOptions)
    }

    const handleTargetsChange = ( selectedOptions ) => {
        setTargetValues(selectedOptions)
    }

    const handlePriceChange = ( event ) => {
        setPrice(Math.round(event.target.value))
    }

    const handleDescriptionChange = ( event ) => {
        setDescription(event.target.value)
    }

    const handleRequest = async () => {
        console.log(GFilter);
        let targets = []
        let paramObj = {
            "science_id":GFilter.sciences_id[0]
        }

        if (!Object.hasOwn(GFilter, 'lesson_targets'))
        {
            targetValues.forEach( t => {
                targets.push(t.value)
            })
            paramObj.lesson_targets = targets
        }
        else
        {
            paramObj.lesson_targets = GFilter.lesson_targets
        }

        let statuses = []
        if (!Object.hasOwn(GFilter, 'teacher_statuses'))
        {
            statusValues.forEach( s => {
                statuses.push(s.value)
            })
            paramObj.teacher_statuses = statuses
        }
        else
        {
            paramObj.teacher_statuses = GFilter.teacher_statuses
        }

        if (!Object.hasOwn(GFilter, 'ages'))
            paramObj.age_category = ageValue.value
        else
            paramObj.age_category = GFilter.ages[0]

        paramObj.max_price = price

        paramObj.request_description = description
        console.log(paramObj);
        const response = await PostService.createTeacherRequest(paramObj)
        if ( response.status !== 200 )
        {
            console.log(response);
        }
        console.log(response);
        goRequests()
    }

    useEffect( () => {
        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === GFilter.sciences_id[0] )


        let scTargetList = []
        scienceObj.targets.forEach( trg => {
            const targetObj = JSON.parse(localStorage.getItem('GTargets')).find( t => t.id === trg )
            scTargetList.push({value:targetObj.id, label:targetObj.name})
        })
        
        if (Object.hasOwn(GFilter, 'lesson_targets'))
        {
            let str = ''
            GFilter.lesson_targets.forEach( (target) => {
                str+= `${JSON.parse(localStorage.getItem('GTargets')).find(e => e.id === target).name}; `
            })
            setStrTargets(str.slice(0, str.length-2))
        }
        else
        {
            let scTargetList = []
            scienceObj.targets.forEach( trg => {
                const targetObj = JSON.parse(localStorage.getItem('GTargets')).find( t => t.id === trg )
                scTargetList.push({value:targetObj.id, label:targetObj.name})
            })
            setScTargets(scTargetList)
            setAddInfo(true)
        }
        
        if (Object.hasOwn(GFilter, 'teacher_statuses'))
        {
            let str = ''
            GFilter.teacher_statuses.forEach( (status) => {
                str+= `${JSON.parse(localStorage.getItem('GStatuses')).find(e => e.id === status).name}; `
            })
            setStrStatuses(str.slice(0, str.length-2))
        }
        else
        {
            let scStatusList = []
            scienceObj.statuses.forEach( sts => {
                const statusObj = JSON.parse(localStorage.getItem('GStatuses')).find( s => s.id === sts)
                scStatusList.push({value:statusObj.id, label:statusObj.name})
            })
            setScStatuses(scStatusList)
            setAddInfo(true)
        }

        if (Object.hasOwn(GFilter, 'ages'))
        {
            let str = ''
            GFilter.ages.forEach( (age) => {
                str+= `${JSON.parse(localStorage.getItem('GAges')).find(e => e.id === age).name}; `
            })
            setStrAges(str.slice(0, str.length-2))
        }
        else
        {
            let scAgesList = []
            scienceObj.ages.forEach( sts => {
                const statusObj = JSON.parse(localStorage.getItem('GAges')).find( s => s.id === sts)
                scAgesList.push({value:statusObj.id, label:statusObj.name})
            })
            setScAges(scAgesList)
            setAddInfo(true)
        }
        
        if ( GFilter.price === 0 )
            setAddInfo(true)
        setPrice(GFilter.price)

        if ( GFilter.description.length === 0 )
            setAddInfo(true)
        setDescription(GFilter.description)
        
    },[])

    return (
        <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
            <div className='request-content' onClick={e => e.stopPropagation()}>
                <div className="modal-title">{addInfo ? `Дополните запрос` : `Запрос отправлен!`}</div>
                <div className='edit-title'>Предмет:</div>
                <div className='edit-value'>{JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === GFilter.sciences_id[0] )?.name}</div>
                <div className='edit-title'>Статус:</div>
                {
                    (Object.hasOwn(GFilter, 'teacher_statuses')) ?
                    <div>{strStatuses}</div>
                    :
                    <Select placeholder={'Статус преподавателя'} isMulti isSearchable={false} onChange={handleStatusesChange}
                    className='science-status select' options={scStatuses} value={statusValues}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                }
                
                <div className='edit-title'>Направления:</div>
                {
                    (Object.hasOwn(GFilter, 'lesson_targets')) ? 
                    <div>{strTargets}</div>
                    :
                    <Select placeholder={'Направления'} isMulti isSearchable={false} onChange={handleTargetsChange}
                    isClearable className='science-targets select' options={scTargets} value={targetValues}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                }
                <div className='edit-title'>Возраст:</div>
                {
                    (Object.hasOwn(GFilter, 'ages')) ?
                    <div>{strAges}</div>
                    :
                    <Select placeholder={'Возраст'} isSearchable={false} onChange={handleAgesChange}
                    isClearable className='science-ages select' options={scAges} value={ageValue}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                }
                <div className='edit-title'>Цена до ₽/ час:</div>
                {
                    (GFilter.price !== 0) ?
                    <div>{`${price}`}</div>
                    :
                    <input className='teacher-name input' placeholder='Цена до' value={price} onChange={handlePriceChange} />
                }
                <div className='edit-title'>Дополнительная информация</div>
                {
                    (GFilter.description.length !== 0) ?
                    <div>{GFilter.description}</div>
                    :
                    <TextareaAutosize value={description} className='teacher-description' placeholder='Напишите цель занятий, удобное время и дни для уроков или другую важную информацию для репетитора' onChange={handleDescriptionChange}/>
                }
                <div className='button-request' onClick={addInfo ? handleRequest : goRequests}>{addInfo ? `Создать запрос`:`Мои заявки`}</div>
            </div>
        </div>
    )
}

export default ModalRequest