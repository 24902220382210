import ResponseCard from "./ResponseCard";

function ResponseList( { responses } ) {
    
    return (
        <div className="responses-list">
            <div className="responses-list-header">
                Ответы на запросы
            </div>
            { responses !== undefined && responses.length !== 0 ?
                <div>
                    {responses.map((response, index) => 
                        <ResponseCard responseInit={response} />
                    )}
                </div>
                : <></>
            }

        </div>
    )
}

export default ResponseList